import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`12 Days Of Christmas`}</strong></p>
    <p>{`1-SDHP (75/55) (RX+ 95/65)`}</p>
    <p>{`2-Thrusters`}</p>
    <p>{`3-Push Press`}</p>
    <p>{`4-Power Cleans`}</p>
    <p>{`5-Power Snatch`}</p>
    <p>{`6-KBS’s (53/35) (RX+ 70/53)`}</p>
    <p>{`7-Pullups (RX+ CTB Pullups)`}</p>
    <p>{`8-K2E’s`}</p>
    <p>{`9-Box Jumps (24/20)(RX+ 30/24)`}</p>
    <p>{`10-Double Unders`}</p>
    <p>{`11-Burpees`}</p>
    <p>{`12-OH Walking Lunges (45/25`}{`#`}{`)`}</p>
    <p>{`*`}{`Beginners version will also be available.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Today will be our 12 Days of Christmas wod at 9:00 & 10:30am. All
other classes are cancelled.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`We will be closed Saturday, Christmas Day.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      